<template>
  <!--begin::Post-->
  <div class="post d-flex flex-column-fluid" id="kt_post">
    <!--begin::Container-->
    <div id="kt_content_container" class="container">
      <!--begin::Basic info-->
      <div class="card mb-5 mb-xl-10">
        <!--begin::Card header-->
        <div
          class="card-header border-0 cursor-pointer"
          role="button"
          data-bs-toggle="collapse"
          data-bs-target="#kt_account_profile_details"
          aria-expanded="true"
          aria-controls="kt_account_profile_details"
        >
          <!--begin::Card title-->
          <div class="card-title m-0">
            <h3 class="fw-bolder m-0">แก้ไขประเภทสินค้า</h3>
          </div>
          <!--end::Card title-->
        </div>
        <!--begin::Card header-->

        <!--begin::Content-->
        <div id="kt_account_profile_details" class="collapse show">
          <!--begin::Form--> 
          <Form
            v-if="product_type"
            @submit="onSubmitProduct_type"
            :validation-schema="product_typeValidator"
            id="kt_account_profile_details_form"
            class="form"
            novalidate="novalidate"
          >
            <!--begin::Card body-->
            <div class="card-body border-top p-9">
              <!--begin::Input group-->
              <div class="row mb-6">
                <!--begin::Label-->
                <label class="col-lg-4 col-form-label required fw-bold fs-6"
                  >ประเภทสินค้า</label
                >
                <!--end::Label-->

                <!--begin::Col-->
                <div class="col-lg-8 fv-row">
                 <Field type="hidden" name="id" v-model="product_type.id" />
                  <Field
                    type="text"
                    name="name"
                    class="form-control form-control-lg form-control-solid"
                    placeholder="ประเภทสินค้า"
                     v-model="product_type.name"
                  />
                  <div class="fv-plugins-message-container">
                    <div class="fv-help-block">
                      <ErrorMessage name="name" />
                    </div>
                  </div>
                </div>
                <!--end::Col-->
              </div>
              <!--end::Input group-->
    
            </div>
            <!--end::Card body-->

            <!--begin::Actions-->
            <div class="card-footer d-flex justify-content-end py-6 px-9">
              <button
                type="submit"
                id="kt_account_profile_details_submit"
                ref="submitButton"
                class="btn btn-primary"
              >
                <span class="indicator-label"> ยืนยัน </span>
                <span class="indicator-progress">
                  กรุณารอสักครู่ ...
                  <span
                    class="spinner-border spinner-border-sm align-middle ms-2"
                  ></span>
                </span>
              </button>
            </div>
            <!--end::Actions-->
          </Form>
          <!--end::Form-->
        </div>
        <!--end::Content-->
      </div>
      <!--end::Basic info-->
    </div>
  </div>
</template>

<script>
import { defineComponent, onMounted, ref } from "vue";
import { ErrorMessage, Field, Form } from "vee-validate";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import * as Yup from "yup";
import useSweetalert from "@/core/helpers/sweetalert2";
import useProductType from "@/core/services/api/travel/otop/product_type";
import { checkPage } from "@/core/helpers/checkpage";
import "vue-advanced-cropper/dist/style.css";
import { useRouter } from "vue-router";
export default defineComponent({
  name: "otop-product-type-edit",
  components: {
    ErrorMessage,
    Field,
    Form,
  },
    props: ["id"],
  setup(props) {
    const router = useRouter();
    const { product_type, getProductType, updateProductType } = useProductType();
    const { Sconfirm, SToast } = useSweetalert();
    const submitButton = ref(null);

    const product_typeValidator = Yup.object().shape({
      name: Yup.string()
        .required("กรุณากรอก ประเภทสินค้า")
        .label("ประเภทสินค้า"),
    });

    const onSubmitProduct_type = (values) => {
      Sconfirm("ยืนยัน การแก้ไขประเภทสินค้า", "question").then(
        async (result) => {
          if (result.isConfirmed) {
            if (submitButton.value) {
              // Activate indicator
              submitButton.value.setAttribute("data-kt-indicator", "on");
              submitButton.value.setAttribute("disabled", "disabled");
            }
            await updateProductType(values)
              .then(() => {
                submitButton.value?.removeAttribute("data-kt-indicator");
                submitButton.value?.removeAttribute("disabled");
                SToast("success", "แก้ไข ประเภทสินค้า สำเร็จ");
              })
              .catch(() => {
                submitButton.value?.removeAttribute("data-kt-indicator");
                submitButton.value?.removeAttribute("disabled");
              });
          }
        }
      );
    };



    onMounted(() => {
        if (!props.id) {
        return router.push({ name: "apps.travels.otop.product-type.index" });
      }
      getProductType(props.id);
      checkPage("otop-product-type-edit");
      setCurrentPageBreadcrumbs("แก้ไขประเภทสินค้า", ["ส่งเสริมการท่องเที่ยว","สินค้าชุมชน"]);
    });

    return {
      product_type,
      submitButton,
      onSubmitProduct_type,
      product_typeValidator
    };
  },
});
</script>
<style scoped>
</style>

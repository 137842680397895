import { ref } from "vue";
import ApiService from "@/core/services/ApiService";
import { useRouter } from "vue-router";
export default function useProductType() {
  const product_type = ref();
  const router = useRouter();

  const getProductTypes = async (page) => {
    return await ApiService.query("otop_product_types",page);
  };

  const getProductType = async (id) => {
    let response = await ApiService.get("otop_product_types/" + id);

    product_type.value = response.data.data;
  };

  const destroyProductType = async (id) => {
    await ApiService.delete("otop_product_types/" + id);
  };

  const stoerProductType = async (data) => {
    await ApiService.post("otop_product_types", data);
    await router.push({ name: "apps.travels.otop.product-type.index" });
  };

  const updateProductType = async (data) => {
    await ApiService.put("otop_product_types/" + data.id, data);
    await router.push({
      name: "apps.travels.otop.product-type.edit",
      params: { id: data.id },
    });
  };

  

  return {
    getProductTypes,
    getProductType,
    product_type,
    destroyProductType,
    stoerProductType,
    updateProductType,
  };
}
